<template>
  <div class="news">
    <div class="text-h4 font-weight-bold mb-6">{{ $t('news.title') }}</div>
    <v-card width="100%" rounded="lg" flat class="pa-md-9 px-3 py-5 mb-6">
      <v-text-field
        class="field64"
        v-model="search"
        :placeholder="$t('news.search')"
        hide-details
        outlined
        required
        color="primary"
        height="68"
      >
        <template v-slot:prepend-inner>
          <img width="24" height="24" src="@/assets/icon/search.svg" />
        </template>
      </v-text-field>
      <v-tabs background-color="transparent" v-model="tab" class="mt-6" height="24">
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab v-for="(item, i) in $t('news.tabs')" :key="i" class="text-body-1 gray--text" active-class="black--text font-weight-bold">
          {{ item }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="i in $t('news.tabs').length" :key="i" class="accent">
        <Main />
        <Popular />
        <All />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Main from '../components/news/Main.vue';
import Popular from '../components/news/Popular.vue';
import All from '../components/news/All.vue';

export default {
  components: {
    Main,
    Popular,
    All,
  },
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: '',
      tab: 0,
    };
  },
  methods: {
    pastTime(time) {
      if (Date.now() - time < 3600000) {
        return `${new Date(Date.now() - time).getMinutes()} m`;
      } else if ((Date.now() - time) / 3600000 < 24) {
        return `${Math.floor((Date.now() - time) / 3600000)} h`;
      } else {
        return `${Math.floor((Date.now() - time) / (3600000 * 24))} d`;
      }
    },
  },
};
</script>

<style lang="scss">
#app .news {
  .field64 {
    fieldset {
      margin-top: -3px;
    }
    .v-text-field__slot {
      margin-top: 2px !important;
    }
  }
  .v-tab {
    text-transform: initial;
    height: 24px;
  }
  .v-tab:not(:last-child) {
    border-right: 1px solid #ebeff4;
  }
  .img {
    width: 100%;
    height: 100%;
    background: linear-gradient(341.55deg, rgba(0, 146, 188, 0.31) 0.4%, rgba(255, 255, 255, 0.31) 117.44%);
  }
  .main-news {
    max-width: 258px;
    height: 168px;
  }
}
@media screen and (max-width: 1199px) {
  #app .news {
    .main-news {
      max-width: 60%;
    }
  }
}
@media screen and (max-width: 999px) {
  #app .news {
    .f30 {
      font-size: 1.2rem;
    }
  }
}
@media screen and (max-width: 599px) {
  #app .news {
    .f30 {
      font-size: 1rem;
    }
    .main-news {
      max-width: 100%;
    }
  }
}
</style>
